var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-assignment-types"},[(!_vm.subPermission.index)?_c('content-not-view'):(!_vm.rows.length)?_c('Loading'):_c('b-card',[_c('div',{staticClass:"custom-search d-md-flex justify-content-between mb-1"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("g.searchLabel")))]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":_vm.$t('g.searchHere'),"type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),_c('div',[(_vm.subPermission.store == true)?_c('b-button',{staticClass:"w-100",attrs:{"to":{ name: 'addAssignmentType' },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("g.assignmentTypes/add_assignment_type"))+" ")]):_vm._e()],1)],1),_c('vue-good-table',{attrs:{"styleClass":"vgt-table striped","columns":_vm.columns,"rows":_vm.rows,"search-options":{ enabled: true, externalQuery: _vm.searchTerm },"sort-options":{ enabled: false },"select-options":{ enabled: true, selectAllByGroup: true },"pagination-options":{ enabled: true, perPage: _vm.pageLength }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.assignmentTypes/assignment_type_name"))+" ")]):_vm._e(),(props.column.label === 'is_available')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.assignmentTypes/is_available"))+" ")]):_vm._e(),(
            props.column.label === 'actions' &&
            (_vm.subPermission.update || _vm.subPermission.destroy)
          )?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.assignmentTypes/actions"))+" ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row ? props.row.name : "Name")+" ")]):_vm._e(),(props.column.field === 'is_available')?_c('b-badge',{staticClass:"text-nowrap",attrs:{"variant":props.row.is_available ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(props.row ? _vm.$t(("" + (props.row.is_available))) : null)+" ")]):_vm._e(),(
            props.column.field === 'actions' &&
            (_vm.subPermission.update || _vm.subPermission.destroy)
          )?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.subPermission && _vm.subPermission.update)?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'editAssignmentType',
                  params: { id: props.row.id },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.edit")))])],1):_vm._e(),(_vm.subPermission && _vm.subPermission.destroy)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteAlert(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.delete")))])],1):_vm._e()],1)],1)]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.pagelength"))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.of"))+" "+_vm._s(props.total)+" "+_vm._s(_vm.$t("g.pageText"))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('template',{slot:"emptystate"},[_c('span',{staticClass:"text-center",staticStyle:{"width":"100%","display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.$t("g.no_data_for_table"))+" ")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }